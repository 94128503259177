import React, { useState, useEffect } from "react"
import '../styles/JoinUs.css';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import { registerUrl } from '../utils/misc'


function Testimonial({ text, author, i }) {
    return <div className={'testimonial' + (!text ? " empty" : "")} name={`testimonial-${i}`}>
        <div className='txt'>{text}</div>
        <div className='author'><i>{author}</i></div>
    </div>
}

const isBrowser = typeof window !== "undefined"

function isInViewPort(ele) {
    if (!isBrowser) return false

    var rect = ele.getBoundingClientRect();

    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
        rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
    );
}

function TestimonialC() {
    const [testimonialSelected, setTestimonialSelected] = useState(1)
    const [sliderInView, setSliderInView] = useState(false)
    const { t } = useTranslation('joinus');

    useEffect(() => {
        setInterval(() => {
            const ele = document.getElementById("testimonials")
            if (ele && isInViewPort(ele)) {
                setSliderInView(true)
            } else {
                setSliderInView(false)
            }
        }, 1000)
    }, [])

    useEffect(() => {
        const timer = setTimeout(() => {
            try {
                if (sliderInView) {
                    const newTestimonialSelected = (testimonialSelected + 1) % 5
                    setTestimonialSelected(prev => newTestimonialSelected)
                    const ele = document.getElementsByName(`testimonial-${newTestimonialSelected}`)
                    if (ele.length > 0) ele[0].scrollIntoView({ behavior: "smooth", block: "nearest", inline: "center" })
                }
            } catch (e) {
                console.log(e)
            }
        }, 4000)
        return () => clearTimeout(timer);
    }, [testimonialSelected, sliderInView])

    return <div className='testimonials-c'>
        <div style={{ fontSize: "1.25em", margin: "1rem auto" }}>{t('testimonials.satisfaction')}: <b>9.2 / 10</b></div>
        <div>

            <div className='testimonials' id="testimonials">
                <Testimonial i={0} text={"I was convinced to use Take a Seat because of how user friendly it is."} author="Flamingo R." />
                <Testimonial i={1} text={"Feasibility and accessibility to operate without any hassle."} author="Kutumb C." />
                <Testimonial i={2} text={"Quicker & more efficient ordering."} author="QR menus user" />
                <Testimonial i={3} text={"Very easy for non tech savvy person."} author="Kutumb C." />
                <Testimonial i={4} text={"I believe Take a Seat will make my business more visible to customers."} author="Bolan" />
            </div>
        </div>
        <div className='selector-c'>
            {[...Array(5).keys()].map(i =>
                <div
                    key={`selector-2-${i}`}
                    className={'selector' + (testimonialSelected === i ? " selected" : "")}
                    onClick={() => {
                        const ele = document.getElementsByName(`testimonial-${i}`)
                        if (ele.length > 0) ele[0].scrollIntoView({ behavior: "smooth", block: "nearest", inline: "center" })
                        setTestimonialSelected(i)
                    }}
                >•</div>
            )}
        </div>
    </div>
}


function JoinUsPage() {
    const { t } = useTranslation();
    const [rUrl, setRUrl] = React.useState('https://admin.takeaseat.io/register?solution=reservations')

    useEffect(() => {
        setRUrl(registerUrl())
    }, [])

    return <div id="join-us" className="page">
        <div className="main-container" style={{ marginBlock: "4rem" }}>
            <h2 className="title" style={{ textAlign: "center" }}>{t('join-us-first-title.0')}<br /><br />{t('join-us-first-title.1')}</h2>
            <div className="refs" style={{ lineHeight: "1rem" }}>
                <Link to='/live-demo/reservations/' style={{}}>
                    <div><img src="/static/media/icons/demo-logo.svg" alt="Demo logo" style={{ height: "2rem", width: "auto" }} width="906" height="32" /></div>
                </Link>
            </div>
            <TestimonialC />
            <br />
            <div style={{ display: "flex", marginTop: "2rem" }} className='tas-button-wrapper'>
                <a href={rUrl} rel="me" className="tas-button"
                    onClick={() => {
                        if (isBrowser) {
                            window.gtag_report_conversion(rUrl + "&tier=standard&source=landing.joinus")
                            window.gtag('event', 'click_on_get_started');
                        }
                    }}>
                    <div>{t('free-sign-up')}</div>
                </a>
            </div>
        </div>
    </div>
}

export default JoinUsPage;
